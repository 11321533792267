import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import Subscribe from '../containers/home/subscribe/subscribe';
import PostCardMinimal from '../components/post-card-minimal/post-card-minimal';
import {
  CategoryWrapper,
  CategoryContainer,
  CategoryTitle,
  CategoryList,
} from './templates.style';
import SEO from '../components/seo';
import { getImage } from '../utils';
import Pagination from '../components/pagination/pagination';
import CategoryDetails from '../components/category-details';

const CategoryPage: React.FunctionComponent = (props: any) => {
  const { data, pageContext, path } = props;
  const { category } = pageContext;
  const page = data.allSitePage.edges.find(
    ({ node }: any) => node.path === `${path}/page/1`
  );

  const TotalPage = page?.node.pageContext.numPagesCat;
  const CurrentPage = page?.node.pageContext.currentPage;
  const posts = data.allMdx.edges;

  const { mdx } = data;

  return (
    <Layout>
      <SEO
        title={mdx ? mdx.frontmatter.title : undefined}
        description={mdx ? mdx.frontmatter.excerpt : undefined}
      />
      <Subscribe isIndexPage={false} />
      <CategoryWrapper>
        <CategoryContainer>
          {mdx && <CategoryDetails body={mdx.body} />}
          <CategoryTitle id="list">{category} Posts</CategoryTitle>
          <CategoryList>
            {posts.map(({ node }: any) => {
              const title = node.frontmatter.title || node.fields.slug;
              const postThumbnail =
                node.frontmatter.thumbnail != null
                  ? node.frontmatter.thumbnail
                  : node.frontmatter.videoThumbnail;
              const { image, imageType } = getImage(postThumbnail);

              return (
                <PostCardMinimal
                  key={node.fields.slug}
                  title={title}
                  image={image}
                  imageType={imageType}
                  url={node.fields.slug}
                  description={node.frontmatter.excerpt || node.excerpt}
                  date={node.frontmatter.date}
                  category={node.frontmatter.category}
                />
              );
            })}
          </CategoryList>
          {TotalPage >> 1 && (
            <Pagination
              nextLink={`${path}/page/2/#list`}
              currentPage={CurrentPage}
              totalPage={TotalPage}
            />
          )}
        </CategoryContainer>
      </CategoryWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($category: String, $mdxFilter: String) {
    allMdx(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            category
            videoThumbnail {
              publicURL
              extension
            }
            thumbnail {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 167, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }

    allSitePage {
      edges {
        node {
          path
          pageContext
        }
      }
    }

    mdx(slug: { eq: $mdxFilter }) {
      body
      frontmatter {
        type
        title
        excerpt
      }
    }
  }
`;

export default CategoryPage;
